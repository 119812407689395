import React from "react"
import Layout from "../../components/layout"
//import styles from "../components/kontakt.module.css"
export default props => (
  <Layout location={props.location.pathname}>
    <h2>Psychoterapia osób dorosłych</h2>
    
    <p>Zapraszam wszystkich, którzy chcieliby przyjrzeć się sobie, swoim relacjom z innymi lub potrzebują wsparcia dla siebie, swoich bliskich w trudnych sytuacjach życiowych, w poradzeniu sobie z problemem. Podczas wspólnych spotkań ważne jest dla mnie stworzenie dla drugiej osoby bezpiecznej, pełnej szacunku przestrzeni uwzględniającej jej autonomię, zasoby oraz indywidualne potrzeby.</p>

    <p>Zapraszam osoby, które zmagają się z:</p>
    <ul>
      <li>długotrwałym smutkiem, zmęczeniem, rozdrażnieniem</li>
      <li>brakiem motywacji, poczuciem beznadziejności</li>
      <li>samookaleczaniem</li>
      <li>lękiem, z zaburzeniami nastroju (np. z depresją), z zaburzeniami psychosomatycznymi</li>
      <li>z zaburzeniami odżywiania</li>
      <li>z niską samooceną, poczuciem bycia nieakceptowanym/nieakceptowaną</li>
      <li>kryzysem życiowym</li>
      <li>zdarzeniem traumatyzującym i zmagają się z konsekwencjami traumy</li>
      <li>cierpią z powodu nadużyć w przeszłości, doświadczyły przemocy fizycznej, psychicznej, seksualnej</li>
      <li>doświadczają napięć i stresów dnia codziennego</li>
    </ul>
    
    <h2>Oferta</h2>
    <p>
      Na wstępie odbywa się kilka (1-3) spotkań konsultacyjnych, po których
      wspólnie ustalimy, czy i jaka będzie forma naszej dalszej współpracy (np.
      jednorazowa konsultacja, wsparcie psychologiczne, psychoterapia
      indywidualna).
    </p>

    <hr />
    <h2>Cennik</h2>
    <p>Konsultacja, psychoterapia trwa 50 min.</p>
    <ul>
      <li>konsultacja psychologiczna - 180 zł</li>

      <li>psychoterapia indywidualna - 180 zł</li>
      <li>EMDR - 180 zł</li>
      <li>Brainspotting - 180 zł</li>
    </ul>
  </Layout>
)
